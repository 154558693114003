import React, { Fragment } from "react";
import { withRouter } from "react-router";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import LoadingOverlay from "react-loading-overlay";

import { Pagination } from "../../../components/Pagination";
import { useFinancialPeriodStore } from "../../../store/financialPeriodStore";

import Button from "./components/Button";
import HeaderSummary from "./components/HeaderSummary";
import InputDate from "./components/InputDate";
import InputSelect from "./components/InputSelect";

import * as S from "./styles";
import { useOrderTransactionStore } from "../../../store/orderTransactionStore";

function Geral() {
  const {
    loading: isLoadingOrderTransactions,
    startDateFilter: transactionsFilterStart,
    endDateFilter: transactionsFilterEnd,
    setStartDateFilter: setTransactionsFilterStart,
    setEndDateFilter: setTransactionsFilterEnd,
    setFinancialPeriodIdFilter,
    fetchOrderTransactions,
    grouppedOrderTransactions = [],
    total: totalOrderTransactions,
    totalPages: orderTransactionTotalPages,
    page: orderTransactionPage,
    setCurrentPage: setOrdeTransactionCurrentPage,
    resetFilter: resetOrderTransactionFilter,
  } = useOrderTransactionStore();
  const {
    loading: financialPeriodLoading,
    selectedFinancialPeriod,
    summary,
    fetchFinancialPeriods,
    setSelectedFinancialPeriod,
    financialPeriods,
  } = useFinancialPeriodStore();

  const isLoadingPage = isLoadingOrderTransactions || financialPeriodLoading;

  const handleFilter = () => {
    setOrdeTransactionCurrentPage(1);
    fetchOrderTransactions();
  };

  React.useEffect(() => {
    fetchFinancialPeriods();
  }, [fetchFinancialPeriods]);

  React.useEffect(() => {
    if (!financialPeriods.length) {
      return;
    }

    const selectedPeriod = financialPeriods[financialPeriods.length - 1];
    setSelectedFinancialPeriod(selectedPeriod);
  }, [financialPeriods, setSelectedFinancialPeriod]);

  React.useEffect(() => {
    resetOrderTransactionFilter();
    if (selectedFinancialPeriod) {
      setFinancialPeriodIdFilter(selectedFinancialPeriod?.id);
      fetchOrderTransactions();
    }
  }, [
    resetOrderTransactionFilter,
    selectedFinancialPeriod,
    setFinancialPeriodIdFilter,
    fetchOrderTransactions,
  ]);

  return (
    <S.Container>
      <h2 className="margin-12">Visão geral</h2>
      <p className="margin-12">
        Total de vendas concluídas no mês e os valores que as compõem.
      </p>
      <LoadingOverlay active={isLoadingPage} spinner text="Carregando...">
        <S.Content>
          {!isLoadingPage && !selectedFinancialPeriod && (
            <div className="w-100 d-flex flex-column align-items-center justify-content-center p-10">
              <img
                alt="Erro"
                src="assets/media/svg/illustrations/page-error.svg"
                style={{ width: "20rem", height: "auto" }}
              />
              <h3 className="mt-8">Nenhum período financeiro encontrado.</h3>
            </div>
          )}

          {selectedFinancialPeriod && (
            <Fragment>
              <InputSelect
                setSelectedFinancialPeriod={setSelectedFinancialPeriod}
                selectedFinancialPeriod={selectedFinancialPeriod}
                financialPeriods={financialPeriods}
              />
              <HeaderSummary
                financialPeriod={selectedFinancialPeriod}
                financialPeriodSummary={summary}
                financialPeriodInvoice={{
                  invoiceUrl: null,
                  bankSlipUrl: null,
                  nfseUrl: null,
                }}
              />

              <div>
                <div>
                  <h5>Transações por pedido</h5>
                  {selectedFinancialPeriod?.simpleLabel && (
                    <p>
                      Lista de transações por pedido referente a {selectedFinancialPeriod.simpleLabel}.
                    </p>
                  )}

                  <div
                    style={{ gap: 16, alignItems: "flex-end" }}
                    className="input-group"
                  >
                    <DatePicker
                      onChange={setTransactionsFilterStart}
                      selectsStart
                      locale="pt-BR"
                      className="form-control"
                      placeholderText="dd/mm/aaaa"
                      selected={transactionsFilterStart}
                      dateFormat="dd/MM/y"
                      startDate={transactionsFilterStart}
                      endDate={transactionsFilterEnd}
                      minDate={selectedFinancialPeriod?.startsAt}
                      maxDate={
                        transactionsFilterEnd ?? selectedFinancialPeriod?.endsAt
                      }
                      customInput={<InputDate label="Data inicial" />}
                    />
                    <DatePicker
                      onChange={setTransactionsFilterEnd}
                      selectsEnd
                      locale="pt-BR"
                      className="form-control"
                      placeholderText="dd/mm/aaaa"
                      selected={transactionsFilterEnd}
                      dateFormat="dd/MM/y"
                      startDate={transactionsFilterStart}
                      endDate={transactionsFilterEnd}
                      minDate={
                        transactionsFilterStart ??
                        selectedFinancialPeriod?.startsAt
                      }
                      maxDate={selectedFinancialPeriod?.endsAt}
                      customInput={<InputDate label="Data final" />}
                    />
                    <Button title={"Filtrar"} onClick={handleFilter} />
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Pedido</th>
                            <th>Data</th>
                            <th>Tipo de pagamento</th>
                            <th className="text-center">Subtotal</th>
                            <th className="text-center">Entrega</th>
                            <th className="text-center">Taxa de pag. online</th>
                            <th className="text-center">Comissão</th>
                            <th className="text-center">Saldo</th>
                          </tr>
                        </thead>
                        <tbody>
                          {grouppedOrderTransactions.map(
                            (orderTransactions, groupId) => (
                              <Fragment key={`${groupId}`}>
                                {orderTransactions.map((item, i) => (
                                  <tr key={`${i}`}>
                                    {i === 0 && (
                                      <td
                                        className="align-middle"
                                        rowSpan={orderTransactions.length}
                                      >
                                        {item.order_hash}
                                      </td>
                                    )}
                                    {i === 0 && (
                                      <td
                                        className="align-middle"
                                        rowSpan={orderTransactions.length}
                                      >
                                        {format(
                                          new Date(item.order_created_at),
                                          "dd/MM/yyyy"
                                        )}
                                      </td>
                                    )}
                                    <td>{item.payment_type}</td>
                                    <td className="text-center">
                                      {Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                      }).format(item.subtotal_value / 100)}
                                    </td>
                                    <td className="text-center">
                                      {Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                      }).format(item.delivery_value / 100)}
                                    </td>
                                    <td className="text-center bg-gray-100">
                                      {Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                      }).format(
                                        item.online_payment_value / 100
                                      )}
                                    </td>
                                    <td className="text-center bg-gray-100">
                                      {Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                      }).format(item.commission_value / 100)}
                                    </td>
                                    <td className="text-center">
                                      {Intl.NumberFormat("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                      }).format(item.nett_value / 100)}
                                    </td>
                                  </tr>
                                ))}
                              </Fragment>
                            )
                          )}
                        </tbody>
                      </table>

                      {!isLoadingPage &&
                        grouppedOrderTransactions.length <= 0 && (
                          <div className="w-100 d-flex flex-column align-items-center justify-content-center p-10">
                            <img
                              alt="Erro"
                              src="assets/media/svg/illustrations/page-error.svg"
                              style={{ width: "20rem", height: "auto" }}
                            />
                            <h3 className="mt-8">
                              Nenhum transação encontrada.
                            </h3>
                            <p>
                              Pesquise em outro período financeiro ou tente
                              novamente mais tarde.
                            </p>
                          </div>
                        )}

                      {grouppedOrderTransactions?.length > 0 && (
                        <div
                          className="w-100 d-flex justify-content-between align-middle"
                          style={{ marginTop: 10, marginBottom: 10 }}
                        >
                          <div style={{ width: 100 }}>
                            <p>
                              {totalOrderTransactions} resultado
                              {totalOrderTransactions > 1 && "s"}
                            </p>
                          </div>
                          <Pagination
                            totalPages={orderTransactionTotalPages}
                            currentPage={orderTransactionPage}
                            setCurrentPage={setOrdeTransactionCurrentPage}
                          />
                          <div style={{ width: 100 }}></div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
        </S.Content>
      </LoadingOverlay>
    </S.Container>
  );
}

export default withRouter(Geral);
