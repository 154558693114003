import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { FiChevronRight } from "react-icons/fi";

import * as S from "./styles";
import { format } from "date-fns";

import { Pagination } from "../../../components/Pagination";
import { useSettlementPeriodStore } from "../../../store/settlementPeriodStore";
import SummaryModal from "./components/SummaryModal";

function FinancialTransfers() {
  const {
    settlementPeriods,
    fetchSettlementPeriods,
    setSelectedSettlementPeriod,
    selectedSettlementPeriod,
    total: totalSettlementPeriods,
    totalPages: settlementPeriodTotalPages,
    page: settlementPeriodPage,
    setCurrentPage: setSettlementPeriodCurrentPage
  } = useSettlementPeriodStore();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    fetchSettlementPeriods();
  }, [fetchSettlementPeriods]);

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  const handleToggle = async (id) => {
    await setSelectedSettlementPeriod(id);
    toggle();
  }

  return (
    <>
      <SummaryModal
        settlementPeriod={selectedSettlementPeriod}
        isOpen={isOpen}
        toggle={toggle}
      />
      <S.Container>
        <h2 className="p12">Repasses</h2>
        <p className="p12">
          Consulte os valores repassados das vendas com pagamento online
        </p>
        <S.Content>
          <table className="table">
            <thead>
              <tr>
                <th>Total a receber</th>
                <th>Data</th>
                <th>Total pagamento online</th>
                <th>Taxa de pagamento online</th>
                <th>Comissão</th>
                <th>Retido</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {settlementPeriods.map((item) => (
                <tr key={item.id}>
                  <td style={{ color: "#00B333" }}>
                    {Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(item.merchant_split_value / 100)}
                  </td>
                  <td>
                    {format(new Date(`${item.settlement_date} 12:00:00`), "dd/MM/yyyy")}
                  </td>
                  <td>
                    {Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(item.total_value / 100)}
                  </td>
                  <td>
                    {Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(item.online_payment_value / 100)}
                  </td>
                  <td>
                    {Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(item.commission_value / 100)}
                  </td>
                  <td>
                    {Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(item.retained_value / 100)}
                  </td>
                  <td>
                    <S.TransferButton
                      onClick={() => handleToggle(item.id)}
                    >
                      Ver pedidos
                      <FiChevronRight color="#333" size={14} />
                    </S.TransferButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div
            className="w-100 d-flex justify-content-between align-middle"
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            <div style={{ width: 100 }}>
              <p>
                {totalSettlementPeriods} resultado
                {totalSettlementPeriods > 1 && "s"}
              </p>
            </div>
            <Pagination
              totalPages={settlementPeriodTotalPages}
              currentPage={settlementPeriodPage}
              setCurrentPage={setSettlementPeriodCurrentPage}
            />
            <div style={{ width: 100 }}></div>
          </div>
        </S.Content>
      </S.Container>
    </>
  );
}

export default withRouter(FinancialTransfers);
