import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'Nunito';
  width: 100%;
  max-width: 1560px;
  padding: 42px 36px;
  min-height: 100vh;
  margin: 0 auto;

  & .margin-12 {
    margin-left: 12px;
  }
`;

export const Content = styled.div`
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 30px;
`;

export const Summary = styled.div`
  display: flex;
  gap: 18px;
  margin-bottom: 60px;

  & > div {
    width: 562px;
    border: 1px solid #e5e5e5;
    border-radius: 16px;
    padding: 18px 26px;
  }
`;

export const HeaderTitle = styled.div``;
export const HeaderSubTitle = styled.div``;

export const HStack = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify || 'flex-start'};
  gap: ${({ gap }) => `${gap || 0}px`};
  margin-bottom: ${({ mb }) => `${mb || 0}px`};
`;

export const Link = styled.a`
  align-items: center;
  font-family: 'Nunito';
  font-size: 14px;
  font-weight: 600;
  color: #333;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #DCDCDC;
  padding: 4px 10px;
  border-radius: 4px;
  gap: 5px;
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Nunito';
  font-size: ${({ size }) => `${size || 14}px`};
  font-weight: ${({ weight }) => weight || 'normal'};
  color: ${({ color }) => color || '#333'};
  margin-bottom: ${({ mb }) => `${mb || 0}px`};
`;

export const Icon = styled.img`
  height: 16px;
  width: 20px;
  margin-left: ${({ ml }) => `${ml || 0}px`};
  margin-right: ${({ mr }) => `${mr || 0}px`};
`;
