import styled from 'styled-components';

export const Container = styled.button`
  border: 0;
  color: #fff;
  background-color: #1BC5BD;
  height: 44px;
  border-radius: 8px;

  font-family: 'Nunito';
  font-size: 16px;
  padding: 0 38px;
`;
