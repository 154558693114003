import { useCallback, useEffect, useRef, useState } from "react";

import { invoiceService } from "../../services/invoiceService";

export const useInvoices = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState();
  const [legacyInvoices, setLegacyInvoices] = useState([]);

  const shouldFetchLegacyInvoices = useRef(true);

  const isEmptyInvoices =
    data?.data.length === 0 &&
    legacyInvoices.length === 0 &&
    !isLoading &&
    !isError;

  const fetchInvoices = useCallback(async () => {
    try {
      setIsLoading(true);
      setIsError(false);

      if (currentPage === 1 && shouldFetchLegacyInvoices.current) {
        const legacyInvoices = await invoiceService.listLegacyInvoices();

        setLegacyInvoices(legacyInvoices?.faturas);

        shouldFetchLegacyInvoices.current = false;
      }

      const data = await invoiceService.listInvoices({
        page: currentPage,
      });

      setData(data);
    } catch (error) {
      if (error?.response?.status !== 404) {
        setIsError(true);
      }
    } finally {
      setIsLoading(false);
    }
  }, [currentPage]);

  useEffect(() => {
    fetchInvoices();
  }, [fetchInvoices]);

  return {
    invoices: data?.data ?? [],
    legacyInvoices,
    totalPages: data?.paginate_metadata.last_page,
    isLoading,
    isError,
    refetch: fetchInvoices,
    currentPage,
    setCurrentPage,
    isEmptyInvoices,
  };
};
