import PageHeader from "../../../../components/PageHeader";

import * as Styled from "../../styles";

export const Root = ({ children }) => {
  return (
    <Styled.Container>
      <PageHeader title="Faturas" />
      <Styled.Content>{children}</Styled.Content>
    </Styled.Container>
  );
};
