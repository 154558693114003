import { Fragment, useState } from "react";
import ReactTooltip from "react-tooltip";
import { Popover } from "@material-ui/core";

import { ActionButton } from "../ActionButton";

import { formatValueToCurrency, dateUtils } from "../../../../utils";
import { invoicesStatus } from "../../helpers";

import * as Styled from "./styles";

export const Row = ({ row, ...rest }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Fragment>
      <tr {...rest}>
        <Styled.Cell>
          <Styled.TableRowLabel>{row.hash}</Styled.TableRowLabel>
        </Styled.Cell>

        <Styled.Cell>
          <Styled.TableRowLabel>
            De {dateUtils.formatDate(new Date(row.starts_at))} à{" "}
            {dateUtils.formatDate(new Date(row.ends_at))}
          </Styled.TableRowLabel>
        </Styled.Cell>

        <Styled.Cell>
          <Styled.TableRowLabel>
            {formatValueToCurrency(row.balance / -100)}
          </Styled.TableRowLabel>
        </Styled.Cell>

        <Styled.Cell>
          <Styled.TableRowLabel>
            {dateUtils.formatDate(new Date(row.overdues_at))}
          </Styled.TableRowLabel>
        </Styled.Cell>

        <Styled.Cell>
          <Styled.StatusRowLabel status={row.status}>
            {invoicesStatus[row.status].label}
          </Styled.StatusRowLabel>
        </Styled.Cell>

        <Styled.Cell>
          <Styled.ActionsButtonsContainer>
            <ReactTooltip />

            <ActionButton
              href={row.pdf_url}
              icon={<i className="far fa-file" style={{ color: "#1B78B3" }} />}
              data-tip="Fatura"
            />
            {row.nf_urls?.length > 0 && (
              <ActionButton
                onClick={handleClick}
                icon={
                  <Styled.IconContainer>
                    <i
                      className="las la-receipt"
                      style={{ color: "#FF3333", fontSize: 18 }}
                    />
                    <i
                      class="fas fa-sort-down"
                      style={{ color: "#777777" }}
                    ></i>
                  </Styled.IconContainer>
                }
                data-tip="Nota fiscal"
              />
            )}
            {(row.status !== "processing" || row.status !== "paid") && (
              <ActionButton
                icon={
                  <i
                    className="far fa-money-bill-alt"
                    style={{ color: "#00B333" }}
                  />
                }
                data-tip="Pagar"
              />
            )}
          </Styled.ActionsButtonsContainer>
        </Styled.Cell>
      </tr>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        style={{
          marginTop: 10,
        }}
      >
        <Styled.TooltipContainer>
          {row.nf_urls
            ?.map((nfs, index) => ({
              type: `Nota fiscal ${index + 1}`,
              url: nfs,
            }))
            .map((nfs) => (
              <Styled.TooltipContent href={nfs.url} target="_blank">
                <Styled.TooltipText>{nfs.type}</Styled.TooltipText>
              </Styled.TooltipContent>
            ))}
        </Styled.TooltipContainer>
      </Popover>
    </Fragment>
  );
};
