import { create } from "zustand";
import apiRemaster from "../services/apiRemaster";
import {
  format,
  startOfMonth,
  endOfMonth,
  startOfDay,
  endOfDay,
} from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

export const useFinanceStore = create()((set, get) => ({
  loading: false,
  status: "pending",
  error: "",
  financialPeriodInvoice: {
    invoiceUrl: null,
    bankSlipUrl: null,
    nfseUrl: null,
  },
  financialPeriodSummary: {
    totalValue: 0,
    subtotalValue: 0,
    deliveryValue: 0,
    commissionValue: 0,
    onlinePaymentTaxValue: 0,
    onlinePaymentValue: 0,
    cashValue: 0,
    cardOnDeliveryValue: 0,
    totalDiscountValue: 0,
    discountValue: 0,
    sponsoredDiscountValue: 0,
    totalOnlinePaymentValue: 0,
    totalOfflinePaymentValue: 0,
    fineValue: 0,
    balanceValue: 0,
    sponsoredDiscountBalanceValue: 0,
    retainedValue: 0,
    orderCount: 0,
    customTransactionValue: 0,
  },
  financialPeriodId: null,
  financialPeriodSimpleLabel: null,
  financialPeriodDetailedLabel: null,
  financialPeriods: [],
  transactionsFilterStartLimit: null,
  transactionsFilterEndLimit: null,
  transactionsFilterStart: null,
  transactionsFilterEnd: null,
  transactions: [],
  filteredTransactions: [],
  currentTransactions: [],
  transactionsStartPeriod: startOfMonth(new Date()),
  transactionsEndPeriod: endOfMonth(new Date()),
  currentPage: 1,
  transactionsPerPage: 10,
  totalPages: 0,
  setFinancialPeriod: async (period) => {
    // set({ loading: true, status: "pending" })
    try {
      const { data } = await apiRemaster.get(
        `finance/financial-periods/${period.id}`
      );

      const summary = data.financial_period_summary;

      let financialPeriodDetailedLabel =
        format(period.startAt, "dd/MM/yyyy") + " ";
      financialPeriodDetailedLabel += format(period.endAt, "dd/MM/yyyy");

      let balanceType = "accumulated";
      summary.balance_value *= -1;

      if (summary.balance_value > 3000) {
        balanceType = "normal";
      }

      set({
        financialPeriodId: period.id,
        financialPeriodSimpleLabel: period.label,
        financialPeriodDetailedLabel,
        financialPeriodSummary: {
          totalValue: summary.total_value,
          subtotalValue: summary.subtotal_value,
          deliveryValue: summary.delivery_value,
          commissionValue: summary.commission_value,
          onlinePaymentTaxValue: summary.online_payment_tax_value,
          onlinePaymentValue: summary.online_payment_value,
          cashValue: summary.cash_value,
          cardOnDeliveryValue: summary.card_on_delivery_value,
          totalDiscountValue: summary.total_discount_value,
          discountValue: summary.discount_value,
          sponsoredDiscountValue: summary.sponsored_discount_value,
          totalOnlinePaymentValue: summary.total_online_payment_value,
          totalOfflinePaymentValue: summary.total_offline_payment_value,
          fineValue: summary.fine_value,
          balanceValue: summary.balance_value,
          balanceType,
          sponsoredDiscountBalanceValue:
            summary.sponsored_discount_balance_value,
          retainedValue: summary.retained_value,
          orderCount: summary.order_count,
          customTransactionValue: summary.custom_transaction_value,
          commissionableSubtotalValue: summary.commissionable_subtotal_value,
          commissionableDeliveryValue: summary.commissionable_delivery_value,
        },
        financialPeriodInvoice: {
          invoiceUrl: null,
          bankSlipUrl: null,
          nfseUrl: null,
        },
        transactionsFilterStartLimit: period.startAt,
        transactionsFilterEndLimit: period.endAt,
        transactionsFilterStart: period.startAt,
        transactionsFilterEnd: period.endAt,
        // loading: false,
        // status: "success"
      });

      get().getOrderTransactions();
    } catch (e) {
      set({ status: "error", error: e.message });
    }
  },
  getFinancialPeriods: async () => {
    set({ loading: true, status: "pending" });
    try {
      const { data } = await apiRemaster.get(`finance/financial-periods`);
      const periods = data.financial_periods.map((period) => {
        const date = new Date(period.end_at);
        return {
          id: period.id,
          label: format(date, "MMMM 'de' yyyy", { locale: ptBR }),
          startAt: new Date(period.start_at),
          endAt: new Date(period.end_at),
        };
      });

      const selectedPeriod = periods[0];

      set({ financialPeriods: periods });

      get().setFinancialPeriod(selectedPeriod);
    } catch (e) {
      set({ status: "error", error: e.message });
    }
  },
  getOrderTransactions: async () => {
    set({ loading: true, status: "pending" });
    try {
      const financialPeriodId = get().financialPeriodId;
      const { data } = await apiRemaster.get(
        `finance/order-transactions?financial_period_id=${financialPeriodId}`
      );

      let ordeTransactionsMap = {};

      data.order_transactions.forEach((transaction) => {
        if (!ordeTransactionsMap[transaction.order_hash]) {
          ordeTransactionsMap[transaction.order_hash] = {
            order_hash: transaction.order_hash,
            created_at: transaction.created_at,
            order_created_at: transaction.order_created_at,
            operations: [],
          };
        }

        ordeTransactionsMap[transaction.order_hash].operations.push(
          transaction
        );
      });

      ordeTransactionsMap = Object.values(ordeTransactionsMap);

      const totalPages = Math.ceil(
        ordeTransactionsMap.length / get().transactionsPerPage
      );

      set({
        transactions: ordeTransactionsMap,
        filteredTransactions: ordeTransactionsMap,
        status: "success",
        totalPages,
        currentPage: 1,
      });
      get().changeFilter();
    } catch (e) {
      set({ status: "error", error: e.message });
      set({ transactions: [] });
    } finally {
      set({ loading: false });
    }
  },
  setTransactionsFilterStart: (date) => {
    set({ transactionsFilterStart: date });
  },
  setTransactionsFilterEnd: (date) => {
    set({ transactionsFilterEnd: date });
  },
  setCurrentPage: (page) => {
    set({ currentPage: page });
    get().changeTransactions();
  },
  nextPage: () => {
    set((state) => ({
      currentPage: Math.min(
        state.currentPage + 1,
        Math.ceil(state.transactions.length / state.transactionsPerPage)
      ),
    }));
    get().changeTransactions();
  },
  changeFilter: () => {
    const start = get().transactionsFilterStart;
    const end = get().transactionsFilterEnd;
    const { transactions, transactionsPerPage } = get();
    const filteredTransactions = transactions.filter(
      (transaction) =>
        new Date(transaction.order_created_at) >= startOfDay(start) &&
        new Date(transaction.order_created_at) <= endOfDay(end)
    );
    const totalPages = Math.ceil(
      filteredTransactions.length / transactionsPerPage
    );

    set({
      filteredTransactions,
      transactionsStartPeriod: startOfDay(start),
      transactionsEndPeriod: endOfDay(end),
      totalPages,
      currentPage: 1,
    });
    get().changeTransactions();
  },
  prevPage: () => {
    set((state) => ({ currentPage: Math.max(state.currentPage - 1, 1) }));
    get().changeTransactions();
  },
  changeTransactions: () => {
    const {
      filteredTransactions,
      currentPage,
      transactionsPerPage,
      transactionsStartPeriod,
      transactionsEndPeriod,
    } = get();
    const startIndex = (currentPage - 1) * transactionsPerPage;
    const endIndex = startIndex + transactionsPerPage;

    const arr = filteredTransactions.filter(
      (transaction) =>
        new Date(transaction.order_created_at) >= transactionsStartPeriod &&
        new Date(transaction.order_created_at) <= transactionsEndPeriod
    );

    set({
      currentTransactions: arr.slice(startIndex, endIndex),
    });
  },
  fineReversalRequest: async (data) => {
    if (!data?.description || (!data?.order_id && !data?.order_hash)) {
      return;
    }

    set({ loading: true });
    try {
      await apiRemaster.post(`/finance/reversal-fine`, data);
      return {
        status: true,
      };
    } catch (err) {
      return {
        status: false,
        message: err?.response?.data?.message ?? "",
      };
    } finally {
      set({ loading: false });
    }
  },
}));
