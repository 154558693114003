import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'Nunito';
  width: 100%;
  max-width: 1560px;
  width: 100%;
  padding: 42px 36px;
  margin: 0 auto;

  & .margin-12 {
    margin-left: 12px;
  }
`;

export const Content = styled.div`
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 30px;
  margin-top: 40px;
`;

export const HStack = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify || 'flex-start'};
  gap: ${({ gap }) => `${gap || 0}px`};
  margin-bottom: ${({ mb }) => `${mb || 0}px`};
`;

export const Main = styled.div`
  margin-top: 60px;
`;

export const TransferButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border: 1px solid #DCDCDC;
  padding: 4px 9px 4px 9px;
  border-radius: 4px;
  gap: 12px;
  cursor: pointer;

  font-weight: 400;
`;
