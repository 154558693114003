import React from "react";

import * as S from "./styles";

export function Button({ children, onClick, ...props }) {
  if (props.loading)
    return (
      <S.Button {...props}>
        <S.Spinner /> enviando...
      </S.Button>
    );

  return (
    <S.Button {...props} onClick={onClick}>
      {children}
    </S.Button>
  );
}
