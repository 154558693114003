import styled from 'styled-components';

export const Label = styled.div`
  font-family: 'Nunito';
  font-size: 15px;
  font-weight: 600;
  color: #333;
  margin-bottom: 8px;
`;

export const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  height: 44px;
  border-radius: 8px;
  border: 1px solid #E5EAEE;
  margin: 0 11px;
  width: fit-content;
  min-width: 215px;
`;

export const Input = styled.select`
  height: 100%;
  border: 0;
  border-radius: 8px;
  width: 100%;
`;
