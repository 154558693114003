import styled from "styled-components";

export const Container = styled.div`
  padding: 42px 36px;

  width: 100%;
  max-width: 1560px;
  margin: 0 auto;
`;

export const Content = styled.div`
  width: 100%;
  min-height: 600px;

  -webkit-box-shadow: 0px 0px 31px -8px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 0px 0px 31px -8px rgba(0, 0, 0, 0.19);
  box-shadow: 0px 0px 31px -8px rgba(0, 0, 0, 0.19);
  border-radius: 8px;
  padding: 30px;
  margin-top: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 14px;
`;
