import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 1122px;
`;

export const Content = styled.div`
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 30px;
`;

export const Summary = styled.div`
  display: flex;
  gap: 18px;
  margin-bottom: 60px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  & > div {
    width: 100%;
    border: 1px solid #e5e5e5;
    border-radius: 16px;
    padding: 18px 26px;

    @media (min-width: 1024px) {
      max-width: 562px;
    }
  }
`;

export const HeaderTitle = styled.div``;
export const HeaderSubTitle = styled.div``;

export const HStack = styled.div`
  display: flex;
  justify-content: ${({ $justify }) => $justify || "flex-start"};
  gap: ${({ $gap }) => `${$gap || 0}px`};
  margin-bottom: ${({ $mb }) => `${$mb || 0}px`};
`;

export const Link = styled.a`
  align-items: center;
  font-family: "Nunito";
  font-size: 14px;
  font-weight: 600;
  color: #333;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid #dcdcdc;
  padding: 4px 10px;
  border-radius: 4px;
  gap: 5px;
  span {
    color: #333;
  }
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
  font-family: "Nunito";
  font-size: ${({ $size }) => `${$size || 14}px`};
  line-height: ${({ $size }) => `${$size * 1.35 || 19}px`};
  font-weight: ${({ $weight }) => $weight || "normal"};
  color: ${({ color }) => color || "#333"};
  margin-bottom: ${({ $mb }) => `${$mb || 0}px`};
  gap: 8px;
`;

export const TextWithBorder = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  font-family: "Nunito";
  font-size: ${({ size }) => `${size || 14}px`};
  line-height: ${({ size }) => `${size * 1.35 || 19}px`};
  font-weight: ${({ weight }) => weight || "normal"};
  color: ${({ color }) => color || "#333"};
  margin-bottom: ${({ $mb }) => `${$mb || 0}px`};
  border: 1px solid #dcdcdc;
  padding: 4px 10px;
  border-radius: 4px;
  gap: 8px;
`;

export const Icon = styled.img`
  height: 16px;
  width: 20px;
  margin-left: ${({ ml }) => `${ml || 0}px`};
  margin-right: ${({ mr }) => `${mr || 0}px`};
`;
