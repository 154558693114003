import styled from "styled-components";

export const Image = styled.img`
  width: 25rem;
  height: auto;
`;

export const Title = styled.h2`
  margin-top: 24px;
  text-align: center;
`;

export const Description = styled.span`
  text-align: center;
`;

export const FeedbackContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
`;
