import styled, { css } from 'styled-components';

export const Button = styled.button`
  display: flex;
  border: none;
  background-color: #1BC5BD;
  font-size: 16px;
  font-weight: 600;
  font-family: "Nunito";
  color: #fff;
  padding: 8px 36px;
  border-radius: 8px;
  cursor: pointer;
  align-items: center;

  ${({ disabled }) => disabled && css`
    background-color: #ccc;
    cursor: not-allowed;
  `}

  ${({ loading }) => loading && css`
    background-color: rgba(23, 197, 190, 0.65);
    cursor: not-allowed;
  `}

`;

export const Spinner = styled.div`
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-left-color: #fff;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
  margin-right: 8px;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
