import styled, { css } from 'styled-components';

export const DrawerContainer = styled.div`
  position: fixed;
  top: 0;
  width: 70vw;
  height: 100%;
  background-color: #fff;
  z-index: 99;
  transform: translateX(100%);

  ${({ $position }) => $position === 'left' && css`
    left: 0;
    transform: translateX(-100%);
  `}

  ${({ $position }) => $position === 'right' && css`
    right: 0;
    transform: translateX(100%);
  `}

  transition: transform 0.5s ease-in-out;

  ${({ $show }) =>
    $show &&
    css`
      transform: translateX(0);
    `}

  @media (max-width: 768px) {
    width: 95vw;
  }
`;

export const Content = styled.div`
  max-height: 100vh;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: none;

  ${({ $show }) =>
    $show &&
    css`
      display: block;
    `}
`;
