import { LegacyRow } from "./LegacyRow";
import { Row } from "./Row";

export const Body = ({ invoices, legacyInvoices, currentPage, lastPage }) => {
  return (
    <tbody>
      {invoices?.map((row) => (
        <Row row={row} key={row.id} />
      ))}

      {(currentPage === lastPage || invoices.length === 0) &&
        legacyInvoices.map((row) => (
          <LegacyRow row={row} key={row.hash_fatura} />
        ))}
    </tbody>
  );
};
