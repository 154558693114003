import React, { useState, useEffect } from "react";
import { useCallback } from "react";
import * as S from "./styles.js";

export const Pagination = ({ totalPages, setCurrentPage, currentPage }) => {
  const [visiblePages, setVisiblePages] = useState([1, 2, 3, 4, 5]);

  const amountPagesToSlice = 4;

  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

  const pageClickDirection = {
    next: currentPage + 1,
    previous: currentPage - 1,
  };

  const visiblePagesOffset = {
    forwardOffset: pages.slice(
      currentPage - 1,
      currentPage + amountPagesToSlice
    ),
    backwardOffset: pages.slice(
      currentPage - amountPagesToSlice - 1,
      currentPage
    ),
  };

  const fillPages = useCallback(() => {
    if (currentPage > visiblePages[visiblePages.length - 1]) {
      setVisiblePages(visiblePagesOffset.forwardOffset);
    }

    if (currentPage < visiblePages[0]) {
      setVisiblePages(visiblePagesOffset.forwardOffset);
    }
  }, [totalPages, currentPage]);

  const handleClickPageNumber = (number) => {
    setCurrentPage(number);
    fillPages();
  };

  const handleClickArrowButton = (pagesPosition) => {
    if (pagesPosition > totalPages || pagesPosition < 1) {
      return;
    }
    setCurrentPage(pagesPosition);
    fillPages();
  };

  const renderPageNumbers = () => {
    if (pages.length < visiblePages.length) {
      return pages?.map((number) => {
        return (
          <S.PageButton
            key={number}
            onClick={() => handleClickPageNumber(number)}
            $active={number === currentPage}
          >
            <li key={number} id={number}>
              {number}
            </li>
          </S.PageButton>
        );
      });
    }
    return (
      <>
        {visiblePages?.map((number) => {
          return (
            <S.PageButton
              key={number}
              onClick={() => handleClickPageNumber(number)}
              $active={number === currentPage}
            >
              <li key={number} id={number}>
                {number}
              </li>
            </S.PageButton>
          );
        })}
      </>
    );
  };

  useEffect(() => {
    fillPages();
  }, [fillPages]);

  return (
    <S.Container>
      <S.PageButton>
        <S.ArrowButton
          className="la la-angle-left"
          onClick={() => handleClickArrowButton(pageClickDirection.previous)}
        />
      </S.PageButton>

      <S.PagesList>{renderPageNumbers()}</S.PagesList>
      <S.PageButton>
        <S.ArrowButton
          className="la la-angle-right"
          onClick={() => handleClickArrowButton(pageClickDirection.next)}
        />
      </S.PageButton>
    </S.Container>
  );
};
