import React, { Fragment } from "react";
import { format } from "date-fns";

import { Orders, Td, Th, Text } from "./styles";

export const OrdersTable = ({ grouppedOrderTransactions, orderTransactions }) => {
  return (
    <Orders>
      <table className="table">
        <thead>
          <tr>
            <Th $center>Pedido</Th>
            <Th $center>Data</Th>
            <Th $center>Tipo de pagamento</Th>
            <Th $center>Total</Th>
            <Th $center>Taxa pag. online</Th>
            <Th $center>Comissão</Th>
            <Th $center>Saldo</Th>
          </tr>
        </thead>
        <tbody>
        {grouppedOrderTransactions && grouppedOrderTransactions.map(
          (orderTransactions, groupId) => (
            <Fragment key={groupId}>
              {orderTransactions.map((item, i) => (
                <tr key={i}>
                  {i === 0 && (
                    <Td
                      $center
                      className="align-middle"
                      rowSpan={orderTransactions.length}
                    >
                      {item.order_hash}
                    </Td>
                  )}
                  {i === 0 && (
                    <Td
                      $center
                      className="align-middle"
                      rowSpan={orderTransactions.length}
                    >
                      {format(
                        new Date(item.order_created_at),
                        "dd/MM/yyyy"
                      )}
                    </Td>
                  )}
                  <Td $center>{item.payment_type}</Td>
                  <Td $center>
                    {Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format((item.total_value) / 100)}
                  </Td>
                  <Td $center>
                    {Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(item.online_payment_value / 100)}
                  </Td>
                  <Td $center>
                    {Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(item.commission_value / 100)}
                  </Td>
                  <Td $center>
                    {Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format((item.nett_value) / 100)}
                  </Td>
                </tr>
              ))}
            </Fragment>
          )
        )}
        </tbody>
        <tfoot>
          <tr>
            <Td colSpan={3}></Td>
            <Td $center>
              <Text size={20} $weight={600}>
                {Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(orderTransactions.reduce((acc, {total_value}) => acc + total_value, 0) / 100)}
              </Text>
            </Td>
            <Td $center>
              <Text size={20} $weight={600}>
                {Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(orderTransactions.reduce((acc, {online_payment_value}) => acc + online_payment_value, 0) / 100)}
              </Text>
            </Td>
            <Td $center>
              <Text size={20} $weight={600}>
                {Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(orderTransactions.reduce((acc, {commission_value}) => acc + commission_value, 0) / 100)}
              </Text>
            </Td>
            <Td $center>
              <Text size={20} $weight={600}>
                {Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(orderTransactions.reduce((acc, {nett_value}) => acc + nett_value, 0) / 100)}
              </Text>
            </Td>
          </tr>
        </tfoot>
      </table>
    </Orders>
  );
};
