import React from "react";
import * as S from "./styles";

function InputSelect({ label, setSelectedFinancialPeriod, selectedFinancialPeriod, financialPeriods, ...props }) {
  const handleChange = (e) => {
    const id = parseInt(e.target.value);

    if (selectedFinancialPeriod.id === id) {
      return;
    }

    financialPeriods.forEach((fp) => {
      if (fp.id === id) {
        setSelectedFinancialPeriod(fp);
      }
    });
  };

  if (!selectedFinancialPeriod) {
    return null;
  }

  return (
    <>
      {!!label && <S.Label>{label}</S.Label>}
      <S.Container>
        <S.Input onChange={handleChange} value={selectedFinancialPeriod.id} {...props}>
          {financialPeriods.map((financialPeriod) => (
            <option key={financialPeriod.id} value={financialPeriod.id}>
              {financialPeriod.simpleLabel}
            </option>
          ))}
        </S.Input>
      </S.Container>
    </>
  );
}

export default InputSelect;
