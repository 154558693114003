import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 15px;
`;

export const PageButton = styled.button`
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-style: solid;
  border-width: ${({ $active }) => ($active ? 1 : 0)}px;
  border-color: ${({ $active }) => $active && "#FF3333"};

  li {
    color: ${({ $active }) => $active && "#FF3333"};
  }
`;

export const PagesList = styled.ul`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 0;
  margin: 0 5px;
`;

export const ArrowButton = styled.i`
  color: #333333;
`;
