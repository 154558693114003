import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: "Nunito";
  height: 100vh;
  padding: 68px 50px;
  overflow-y: scroll;

  @media (max-width: 768px) {
    padding: 32px 16px;
    overflow-y: auto;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #DCDCDC;
  padding: 23px 30px;
  border-radius: 8px;
  margin-bottom: 36px;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 1280px) {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr;
    padding: 16px 20px;
  }

  @media (max-width: 768px) {
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr;
    padding: 16px 20px;
  }
`;

export const Title = styled.h3`
  font-size: 20px;
  font-weight: 600;
  color: #333;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

export const Text = styled.div`
  font-size: ${({ size }) => size || 14}px;
  font-weight: ${({ $weight }) => $weight || 400};
  color: ${({ color }) => color || "#333"};

  @media (max-width: 768px) {
    font-size: ${({ size }) => size * 0.65 || 12}px;
    font-weight: ${({ $weight }) => $weight || 400};
    color: ${({ color }) => color || "#333"};
  }
`;

export const Transfers = styled.div`
  border: 1px solid #DCDCDC;
  border-radius: 8px;
  padding: 12px 20px;
  width: fit-content;
  min-width: 50%;
  margin-bottom: 28px;
`;

export const Orders = styled.div`
  overflow-y: auto;
`;

export const Discount =  styled.div`
  display: flex;
  align-items: center;
  gap: 200px;
  border: 1px solid #DCDCDC;
  border-radius: 8px;
  padding: 16px 20px 25px 33px;
  background-color: #F1F3F4;
  margin-bottom: 28px;
  margin-top: auto;

  > div {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  > div:last-child {
    display: flex;
    flex: 0;
    flex-basis: auto;
  }

  @media (max-width: 1440px) {
    gap: 50px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
    padding: 12px;

    > div {
      justify-content: center;
      align-items: center;
      text-align: center;
    }

  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 70px;
  margin-right: 35px;
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F1F3F4;
  border-radius: 50%;
  height: 27px;
  width: 27px;
  margin: 0 auto;
`;

export const Th = styled.th`
    text-align: ${({ $center }) => $center ? 'center' : 'left'};
`;

export const Td = styled.td`
    text-align: ${({ $center }) => $center ? 'center' : 'left'};
`;
