import styled from 'styled-components';

export const Label = styled.div`
  font-family: 'Nunito';
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin-bottom: 8px;
`;

export const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  height: 44px;
  border-radius: 8px;
  border: 1px solid #E5EAEE;
  padding-right: 11px;
  margin-left: 0;
`;

export const Input = styled.input`
  height: 100%;
  border: 0;
`;
