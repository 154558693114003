import React from "react";
import { FiCalendar } from "react-icons/fi";

import * as S from "./styles";

function InputDate({ label, ...props }) {
  return (
    <>
      {!!label && <S.Label>{label}</S.Label>}
      <S.Container>
        <S.Input {...props} />
        <FiCalendar size={20} color="#B5B5B5" />
      </S.Container>
    </>
  );
}

export default InputDate;
