import React from 'react';

import * as S from './styles';

function Button({ title, ...props }) {
  return <S.Container {...props}>
    {title}
  </S.Container>;
}

export default Button;
