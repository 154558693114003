import { create } from 'zustand'
import apiRemaster from '../services/apiRemaster';

export const useSettlementPeriodStore = create()((set, get) => ({
  loading: false,
  error: null,
  page: 1,
  perPage: 15,
  totalPages: 0,
  total: 0,
  settlementPeriods: [],
  selectedSettlementPeriod: null,

  fetchSettlementPeriods: async () => {
    set({ loading: true });
    try {
      const perPage = get().perPage;
      const page = get().page;
      const searchParamsObj = {
        per_page: perPage,
        page: page,
      };
      const searchParams = new URLSearchParams(searchParamsObj);
      const { data } = await apiRemaster.get(`settlement-periods?${searchParams.toString()}`);
      const paginateMetadata = data.paginate_metadata;

      set({
        settlementPeriods: data.data,
        totalPages: Math.ceil(paginateMetadata.total / perPage),
        total: paginateMetadata.total,
        selectedSettlementPeriod: null,
      });
    } catch (e) {
      set({ loading: false, error: e.message })
    }
  },
  fetchNextPage: () => {
    const totalPages = get().totalPages;
    let newPage = get().page + 1;

    if (newPage > totalPages) {
      newPage = totalPages;
    }

    set({ page: newPage });
    get().fetchSettlementPeriods();
  },
  fetchPreviowsPage: () => {
    let newPage = get().page + 1;

    if (newPage < 1) {
      newPage = 1;
    }

    set({ page: newPage });
    get().fetchSettlementPeriods();
  },

  // SETTERS

  setSelectedSettlementPeriod: async (settlementPeriodId) => {
    set({ loading: true });

    try {
      const { data: settlementPeriod } = await apiRemaster.get(`settlement-periods/${settlementPeriodId}`);

      set({
        selectedSettlementPeriod: settlementPeriod,
        loading: false,
        error: null
      });
    } catch (e) {
      set({ status: "error", error: e.message })
    }
  },

  setCurrentPage: (newPage = 1) => {
    const totalPages = get().totalPages;
    let currentPage = newPage;

    if (currentPage < 1) {
      currentPage = 1;
    }

    if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    set({ page: currentPage });
    get().fetchSettlementPeriods();
  },
}))
