import React, { useState, useCallback } from "react";
import { Modal as RBModal, ModalBody } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { TextArea } from "../TextArea";
import * as S from "./styles";
import { useFinanceStore } from "../../../../../store/financeStore";
import { Button } from "../Button";
import { useToasts } from "react-toast-notifications";

export const Modal = ({ show = false, onClose, data }) => {
  const [description, setDescription] = useState("");
  const { fineReversalRequest, loading } = useFinanceStore();

  const { addToast } = useToasts();

  const handleSubmit = useCallback(async () => {
    const { id_pedido: order_id, hash_pedido: order_hash } = data;
    const resp = await fineReversalRequest({
      description,
      order_id,
      order_hash,
    });

    if (!resp.status && resp.message) {
      addToast(resp.message, {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 4000,
      });

      return;
    }

    if (resp.status) {
      onClose();
    }
  }, [addToast, data, description, fineReversalRequest, onClose]);

  return (
    <RBModal
      style={{ marginTop: "20vh" }}
      size={"lg"}
      show={show}
      onHide={onClose}
    >
      <ModalBody>
        <S.Header>
          <div>
            <S.Title>Solicitação de estorno de multa</S.Title>
            <S.Subtitle>Descreva o motivo da sua solicitação</S.Subtitle>
          </div>
          <S.CloseIcon onClick={onClose}>
            <MdClose size={22} />
          </S.CloseIcon>
        </S.Header>
        <S.Body>
          <TextArea
            name="text"
            cols="30"
            rows="8"
            value={description}
            onChange={(text) => setDescription(text)}
          ></TextArea>
        </S.Body>
        <S.Footer>
          <Button
            disabled={description.length < 20}
            loading={loading}
            onClick={handleSubmit}
          >
            Enviar
          </Button>
        </S.Footer>
      </ModalBody>
    </RBModal>
  );
};

export default Modal;
