import * as Styled from "./styles";

export const Feedback = ({ imagePath, title, description }) => {
  return (
    <Styled.FeedbackContainer>
      <Styled.Image src={imagePath} />
      <Styled.Title>{title}</Styled.Title>
      <Styled.Description>{description}</Styled.Description>
    </Styled.FeedbackContainer>
  );
};
