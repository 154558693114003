import apiV1 from "../api";
import apiV2 from "../apiRemaster";

const listLegacyInvoices = async () => {
  const response = await apiV1.get("faturas/listar");

  return response.data;
};

const listInvoices = async (params) => {
  const response = await apiV2.get("invoices", { params });

  return response.data;
};

export const invoiceService = { listLegacyInvoices, listInvoices };
