import { create } from "zustand";
import apiRemaster from "../services/apiRemaster";
import { format, startOfDay, endOfDay } from "date-fns";

export const useOrderTransactionStore = create()((set, get) => ({
  loading: false,
  error: null,
  page: 1,
  perPage: 15,
  totalPages: 0,
  total: 0,
  orderTransactions: [],
  groupedOrderTransactions: [],
  financialPeriodIdFilter: null,
  settlementPeriodIdFilter: null,
  onlinePaymentFilter: null,
  startDateFilter: null,
  endDateFilter: null,

  fetchOrderTransactions: async () => {
    set({ loading: true });
    try {
      const financialPeriodId = get().financialPeriodIdFilter;
      const settlementPeriodId = get().settlementPeriodIdFilter;
      const onlinePayment = get().onlinePaymentFilter;
      const startDate = get().startDateFilter;
      const endDate = get().endDateFilter;
      const perPage = get().perPage;
      const page = get().page;
      const searchParamsObj = {
        per_page: perPage,
        page: page,
      };

      if (financialPeriodId) {
        searchParamsObj.financial_period_id = financialPeriodId;
      }

      if (settlementPeriodId) {
        searchParamsObj.settlement_period_id = settlementPeriodId;
      }

      if (onlinePayment != null) {
        searchParamsObj.online_payment = onlinePayment;
      }

      if (startDate) {
        searchParamsObj.processed_at_start = format(
          startOfDay(startDate),
          "yyyy-MM-dd HH:mm:ss"
        );
      }

      if (endDate) {
        searchParamsObj.processed_at_end = format(
          endOfDay(endDate),
          "yyyy-MM-dd HH:mm:ss"
        );
      }

      const searchParams = new URLSearchParams(searchParamsObj);
      const { data } = await apiRemaster.get(
        `order-transactions?${searchParams.toString()}`
      );
      const paginateMetadata = data.paginate_metadata;
      const orderTransactions = data.data;

      set({
        orderTransactions,
        grouppedOrderTransactions: Object.values(
          Object.groupBy(orderTransactions, (v) => v.order_id)
        ),
        totalPages: Math.ceil(paginateMetadata.total / perPage),
        total: paginateMetadata.total,
      });
    } catch (e) {
      set({ loading: false, error: e.message });
    } finally {
      set({ loading: false });
    }
  },
  fetchNextPage: () => {
    const totalPages = get().totalPages;
    let newPage = get().page + 1;

    if (newPage > totalPages) {
      newPage = totalPages;
    }

    set({ page: newPage });
    get().fetchOrderTransactions();
  },
  fetchPreviowsPage: () => {
    let newPage = get().page + 1;

    if (newPage < 1) {
      newPage = 1;
    }

    set({ page: newPage });
    get().fetchOrderTransactions();
  },
  //SETTERS
  setFinancialPeriodIdFilter: (financialPeriodId) => {
    set({ financialPeriodIdFilter: financialPeriodId });
  },
  setSettlementPeriodIdFilter: (settlementPeriodId) => {
    set({ settlementPeriodIdFilter: settlementPeriodId });
  },
  setStartDateFilter: (startDate) => {
    set({ startDateFilter: startDate });
  },
  setEndDateFilter: (endDate) => {
    set({ endDateFilter: endDate });
  },
  setPerPage: (perPage) => {
    set({ perPage });
  },
  setOnlinePaymentFilter: (shouldFilterByOnlinePayment) => {
    set({ onlinePaymentFilter: shouldFilterByOnlinePayment });
  },
  setCurrentPage: (newPage = 1) => {
    const totalPages = get().totalPages;
    let currentPage = newPage;

    if (currentPage < 1) {
      currentPage = 1;
    }

    if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    set({ page: currentPage });
    get().fetchOrderTransactions();
  },
  resetFilter: () => {
    set({
      financialPeriodIdFilter: null,
      settlementPeriodIdFilter: null,
      startDateFilter: null,
      endDateFilter: null,
      perPage: 15,
      page: 1,
      orderTransactions: [],
      groupedOrderTransactions: [],
      total: 0,
      totalPages: 0,
    });
  },
}));
