import { create } from "zustand";
import apiRemaster from "../services/apiRemaster";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

export const useFinancialPeriodStore = create()((set, get) => ({
  loading: false,
  error: null,
  page: 1,
  perPage: 5000,
  totalPages: 10,
  financialPeriods: [],
  selectedFinancialPeriod: null,
  summary: {
    totalValue: 0,
    subtotalValue: 0,
    deliveryValue: 0,
    commissionValue: 0,
    onlinePaymentTaxValue: 0,
    onlinePaymentValue: 0,
    cashValue: 0,
    cardOnDeliveryValue: 0,
    totalDiscountValue: 0,
    discountValue: 0,
    sponsoredDiscountValue: 0,
    totalOnlinePaymentValue: 0,
    totalOfflinePaymentValue: 0,
    fineValue: 0,
    balanceValue: 0,
    balanceType: "accumulated",
    sponsoredDiscountBalanceValue: 0,
    retainedValue: 0,
    orderCount: 0,
    customTransactionValue: 0,
    commissionableSubtotalValue: 0,
    commissionableDeliveryValue: 0,
  },

  fetchFinancialPeriods: async () => {
    set({ loading: true });
    try {
      const searchParams = new URLSearchParams({
        per_page: get().perPage,
        page: get().page,
      });
      const { data } = await apiRemaster.get(
        `financial-periods?${searchParams.toString()}`
      );
      const paginateMetadata = data.paginate_metadata;
      const financialPeriods = data.data.map((financialPeriod) => {
        const startsAtDate = new Date(financialPeriod.starts_at);
        const endsAtDate = new Date(financialPeriod.ends_at);
        const simpleLabel = format(endsAtDate, "MMMM 'de' yyyy", {
          locale: ptBR,
        });
        const completeLabel = `${format(startsAtDate, "dd/MM/yyyy")} ${format(
          endsAtDate,
          "dd/MM/yyyy"
        )}`;
        return {
          id: financialPeriod.id,
          simpleLabel,
          completeLabel,
          startsAt: startsAtDate,
          endsAt: endsAtDate,
        };
      });

      set({ financialPeriods, totalPages: paginateMetadata.total });
    } catch (e) {
      set({ loading: false, error: e.message });
    } finally {
      set({ loading: false });
    }
  },
  fetchNextPage: () => {
    const totalPages = get().totalPages;
    let newPage = get().page + 1;

    if (newPage > totalPages) {
      newPage = totalPages;
    }

    set({ page: newPage });
    get().fetchFinancialPeriods();
  },
  fetchPreviowsPage: () => {
    let newPage = get().page + 1;

    if (newPage < 1) {
      newPage = 1;
    }

    set({ page: newPage });
    get().fetchFinancialPeriods();
  },
  fetchFinancialPeriodSummary: async (financialPeriodId) => {
    set({ loading: true });

    try {
      const { data: financialPeriod } = await apiRemaster.get(
        `financial-periods/${financialPeriodId}`
      );

      let balanceType = "accumulated";
      financialPeriod.balance_value *= -1;

      if (financialPeriod.balance_value > 3000) {
        balanceType = "normal";
      }

      const summary = {
        totalValue: financialPeriod.total_value,
        subtotalValue: financialPeriod.subtotal_value,
        deliveryValue: financialPeriod.delivery_value,
        commissionValue: financialPeriod.commission_value,
        onlinePaymentTaxValue: financialPeriod.online_payment_tax_value,
        onlinePaymentValue: financialPeriod.online_payment_value,
        cashValue: financialPeriod.cash_value,
        cardOnDeliveryValue: financialPeriod.card_on_delivery_value,
        totalDiscountValue: financialPeriod.total_discount_value,
        discountValue: financialPeriod.discount_value,
        sponsoredDiscountValue: financialPeriod.sponsored_discount_value,
        totalOnlinePaymentValue: financialPeriod.total_online_payment_value,
        totalOfflinePaymentValue: financialPeriod.total_offline_payment_value,
        fineValue: financialPeriod.fine_value,
        balanceValue: financialPeriod.balance_value,
        balanceType,
        sponsoredDiscountBalanceValue:
          financialPeriod.sponsored_discount_balance_value,
        retainedValue: financialPeriod.retained_value,
        orderCount: financialPeriod.order_count,
        customTransactionValue: financialPeriod.custom_transaction_value,
        commissionableSubtotalValue:
          financialPeriod.commissionable_subtotal_value,
        commissionableDeliveryValue:
          financialPeriod.commissionable_delivery_value,
      };

      set({ summary, loading: false, error: null });
    } catch (e) {
      set({ status: "error", error: e.message });
    } finally {
      set({ loading: false });
    }
  },

  //SETTERS

  setSelectedFinancialPeriod: (financialPeriod) => {
    set({ selectedFinancialPeriod: financialPeriod });
    get().fetchFinancialPeriodSummary(financialPeriod.id);
  },
}));
