import * as Styled from "./styles";

export const LoadingFeedback = () => {
  return (
    <Styled.Container>
      <div
        className="spinner-border m-5"
        role="status"
        style={{ color: "#ff3333" }}
      />
    </Styled.Container>
  );
};
