import { Body } from "./Body";
import { Header } from "./Header";

import * as Styled from "./styles";

export const Table = ({ invoices, legacyInvoices, currentPage, lastPage }) => {
  return (
    <Styled.Table>
      <Header />

      <Body
        invoices={invoices}
        legacyInvoices={legacyInvoices}
        currentPage={currentPage}
        lastPage={lastPage}
      />
    </Styled.Table>
  );
};
