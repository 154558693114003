import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import Layout from "../layout";

import Swal from "sweetalert2";

import Login from "../pages/login";
import Pedidos from "../pages/pedidos";
import Variacoes from "../pages/variacoes";
//NOVO LAYOUT
import Products from "../pages/products";
import ProductsOld from "../pages/products/index_bkp";
import Stock from "../pages/estoque";
import Bairros from "../pages/bairros";

//Configurações:
import ConfiguracoesImpressoras from "../pages/impressoras";
import ConfiguracoesSoftware from "../pages/configuracoes/software";
import Funcionamento from "../pages/funcionamento";
import FormaPagamento from "../pages/formapagamento";

//Financeiro
import PagamentoOnline from "../pages/financeiro/cadastro/";
import FCDados from "../pages/financeiro/cadastro/dados";
import FCDocumentos from "../pages/financeiro/cadastro/documentos";
import FCPlanos from "../pages/financeiro/cadastro/planos";
import FCRecebimentos from "../pages/financeiro/cadastro/recebimentos";
import FVCalendario from "../pages/financeiro/vendas/calendarios";
import FVTransferir from "../pages/financeiro/vendas/vendas/transferir";
import FVVendas from "../pages/financeiro/vendas/vendas";
import FVHistorico from "../pages/financeiro/vendas/historico";
import FVGeral from "../pages/financeiro/geral";
import FinancialTransfers from "../pages/financeiro/financial_transfers";
import { Invoices } from "../pages/faturas";

import Avaliacoes from "../pages/avaliacoes";

import Relatorio from "../pages/relatorio";
import Graficos from "../pages/relatorio/graficos";

import OPReport from "../pages/onlinepayment/report";
import OPBatchReport from "../pages/onlinepayment/batch";

import Painel from "../pages/painel";

import Estabelecimento from "../pages/estabelecimentos";

//Promotions
//import OffersList from "../pages/Promotions/OffersList";
//import CreateOffer from "../pages/Promotions/CreateOffer";

import BulkUpload from "../pages/BulkUpload";

// Verificar se o usuário está autenticado
const isAuthenticated = () => {
  if ("hash" in localStorage) {
    return true;
  } else {
    Swal.fire("Erro!", "Você não está logado, tente novamente.", "error");
    return false;
  }
};
// A função abaixo é para proteger uma rota
const AuthenticatedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/login" }} />
      )
    }
  />
);

const Routes = (props) => (
  <HashRouter>
    <Switch>
      {/* Rotas de Login não necessitam de Auth */}
      <Route exact path="/" component={Login}></Route>
      <Route exact path="/login" component={Login}></Route>
      <Route exact path="/login/:usuario/:hash" component={Login}></Route>
      <Route exact path="/auth" component={Login}></Route>
      {/* ------------------------------------- */}

      <Layout>
        <AuthenticatedRoute exact path="/variacoes" component={Variacoes} />
        <AuthenticatedRoute exact path="/estoque" component={Stock} />
        <AuthenticatedRoute exact path="/pedidos" component={Pedidos} />
        <AuthenticatedRoute exact path="/produtos" component={Products} />
        <AuthenticatedRoute exact path="/produtos/:id" component={Products} />
        <AuthenticatedRoute exact path="/bulkupload" component={BulkUpload} />
        <AuthenticatedRoute
          exact
          path="/configuracoes/bairros"
          component={Bairros}
        />
        <AuthenticatedRoute
          exact
          path="/configuracoes/impressoras"
          component={ConfiguracoesImpressoras}
        />
        <AuthenticatedRoute
          exact
          path="/configuracoes/software"
          component={ConfiguracoesSoftware}
        />
        <AuthenticatedRoute
          exact
          path="/configuracoes/funcionamento"
          component={Funcionamento}
        />
        <AuthenticatedRoute
          exact
          path="/configuracoes/formapagamento"
          component={FormaPagamento}
        />

        <AuthenticatedRoute
          exact
          path="/financeiro/visao-geral"
          component={FVGeral}
        />

          <AuthenticatedRoute
            exact
            path="/financeiro/repasses"
            component={FinancialTransfers}
          />

        <AuthenticatedRoute
          exact
          path="/financeiro/faturas"
          component={Invoices}
        />
        <AuthenticatedRoute
          exact
          path="/financeiro/pagamentoonline"
          component={PagamentoOnline}
        />
        <AuthenticatedRoute
          exact
          path="/financeiro/cadastro/dados"
          component={FCDados}
        />
        <AuthenticatedRoute
          exact
          path="/financeiro/cadastro/documentos"
          component={FCDocumentos}
        />
        <AuthenticatedRoute
          exact
          path="/financeiro/cadastro/planos"
          component={FCPlanos}
        />
        <AuthenticatedRoute
          exact
          path="/financeiro/cadastro/recebimentos"
          component={FCRecebimentos}
        />
        <AuthenticatedRoute
          exact
          path="/financeiro/vendas/vendas"
          component={FVVendas}
        />
        <AuthenticatedRoute
          exact
          path="/financeiro/vendas/vendas/transferir"
          component={FVTransferir}
        />
        <AuthenticatedRoute
          exact
          path="/financeiro/vendas/calendario"
          component={FVCalendario}
        />
        <AuthenticatedRoute
          exact
          path="/financeiro/vendas/historico"
          component={FVHistorico}
        />
        <AuthenticatedRoute
          exact
          path="/onlinepayment/report"
          component={OPReport}
        />
        <AuthenticatedRoute
          exact
          path="/onlinepayment/batch"
          component={OPBatchReport}
        />

        <AuthenticatedRoute exact path="/avaliacoes" component={Avaliacoes} />
        <AuthenticatedRoute exact path="/relatorio" component={Relatorio} />
        <AuthenticatedRoute
          exact
          path="/relatorio/graficos"
          component={Graficos}
        />
        <AuthenticatedRoute exact path="/painel" component={Painel} />
        <AuthenticatedRoute
          exact
          path="/estabelecimentos"
          component={Estabelecimento}
        />

        {/* <AuthenticatedRoute
          exact
          path="/Promotions/OffersList"
          component={OffersList}
        />

        <AuthenticatedRoute
          exact
          path="/Promotions/CreateOffer"
          component={CreateOffer}
        /> */}

        <AuthenticatedRoute exact path="/produtosOld" component={ProductsOld} />
      </Layout>
    </Switch>
  </HashRouter>
);

export default Routes;
