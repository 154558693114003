import React, { useState, useLayoutEffect, useCallback } from "react";
import Api from "../../../../services/api";
import { withRouter } from "react-router";
import { Doughnut } from "react-chartjs-2";
import { PiWarningFill } from "react-icons/pi";
import { RiExchangeDollarLine } from "react-icons/ri";
import { MdBlock } from "react-icons/md";

import Swal from "sweetalert2";
import LoadingOverlay from "react-loading-overlay";
import { Dropdown } from "react-bootstrap";

import ReactTooltip from "react-tooltip";

import DatePicker, { registerLocale } from "react-datepicker";
import InputMask from "react-input-mask";
import pt from "date-fns/locale/pt-BR";
import { Modal } from "./Modal";

import * as S from "./styles";

registerLocale("pt-BR", pt);

export const status = {
  AP: { title: "Aguardando Pagamento", color: "#333333" },
  PI: { title: "Impresso", color: "#333333" },
  PP: { title: "Pendente", color: "#FFCE20" },
  PA: { title: "Aceito", color: "#1B78B3" },
  waiting: { title: "Aguardando motoboy", color: "#333333" },
  PE: { title: "Saiu para entrega", color: "#333333" },
  CR: { title: "Pronto para retirada", color: "#BF6BF2" },
  PD: { title: "Entregue", color: "#00B333" },
  PC: { title: "Recusado", color: "#FF3333" },
  PICKUP_READY: { title: "Pronto para retirada", color: "#333333" },
};

const renderStatus = (orderStatus) => {
  if (!Object.keys(status).includes(orderStatus)) {
    return <span style={{ color: "#999" }}>Outro - ({orderStatus})</span>;
  }
  return (
    <span style={{ color: status[orderStatus].color }}>
      {status[orderStatus].title}
    </span>
  );
};

const FVHistorico = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const [handleFistRender, setHandleFistRender] = useState(true);
  const [caProcessa, setCaProcessa] = useState(false);
  const [pedidos, setPedidos] = useState([]);
  const [periodo, setPeriodo] = useState("7");
  const [dataInicial, setDataInicial] = useState();
  const [dataFinal, setDataFinal] = useState(new Date());

  const [pedidosCountTotal, setPedidosCountTotal] = useState(0);
  const [pedidosSomaTotal, setPedidosSomaTotal] = useState(0);
  const [pedidosCountDinheiro, setPedidosCountDinheiro] = useState(0);
  const [pedidosSomaDinheiro, setPedidosSomaDinheiro] = useState(0);
  const [pedidosCountCartao, setPedidosCountCartao] = useState(0);
  const [pedidosSomaCartao, setPedidosSomaCartao] = useState(0);
  const [pedidosCountOnline, setPedidosCountOnline] = useState(0);
  const [pedidosSomaOnline, setPedidosSomaOnline] = useState(0);

  const filtrar = useCallback(() => {
    setCaProcessa(true);
    setHandleFistRender(false);
    var data = {
      periodo: periodo,
      data_inicio: dataInicial,
      data_final: dataFinal,
      id_estabelecimento: localStorage.getItem("id_estabelecimento"),
    };

    Api.post("pagamentoonline/gerahistorico", data).then((rps) => {
      if (rps.data.status == false) {
        Swal.fire({
          title: "Atenção!",
          icon: "info",
          html: rps.data.erro,
          showCloseButton: true,
          showCancelButton: false,
        });
      } else {
        setPedidos(rps.data.retorno);
        processaDados(rps.data.retorno);
      }
      setCaProcessa(false);
    });
  }, [periodo, dataInicial, dataFinal]);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
    setSelectedOrder(null);
    filtrar();
  }, [filtrar]);

  const handleOpenModal = useCallback((order) => {
    setShowModal(true);
    setSelectedOrder(order);
  }, []);

  useLayoutEffect(() => {
    handleFistRender && filtrar();
  }, [handleFistRender, filtrar]);

  function processaDados(dados) {
    var countPedidosTotal = 0;
    var sumPedidosTotal = 0;
    var countPedidosCartao = 0;
    var sumPedidosCartao = 0;
    var countPedidosDinheiro = 0;
    var sumPedidosDinheiro = 0;
    var countPedidosOnline = 0;
    var sumPedidosOnline = 0;

    dados.map((pedido) => {
      if (pedido?.status === "PD") {
        countPedidosTotal++;
        sumPedidosTotal += parseFloat(pedido?.valor_total);

        if (
          pedido?.pagamento == -2 ||
          pedido?.pagamento == -10 ||
          pedido?.pagamento == -11 ||
          pedido?.pagamento == -12
        ) {
          countPedidosOnline++;
          sumPedidosOnline += parseFloat(pedido?.valor_total);
        } else if (pedido?.pagamento == -1) {
          countPedidosDinheiro++;
          sumPedidosDinheiro += parseFloat(pedido?.valor_total);
        } else {
          countPedidosCartao++;
          sumPedidosCartao += parseFloat(pedido?.valor_total);
        }
      }
      return null;
    });
    setPedidosCountTotal(countPedidosTotal);
    setPedidosSomaTotal(
      sumPedidosTotal.toLocaleString("pt-br", { minimumFractionDigits: 2 })
    );
    setPedidosCountOnline(countPedidosOnline);
    setPedidosSomaOnline(sumPedidosOnline);
    setPedidosCountCartao(countPedidosCartao);
    setPedidosSomaCartao(sumPedidosCartao);
    setPedidosCountDinheiro(countPedidosDinheiro);
    setPedidosSomaDinheiro(sumPedidosDinheiro);
  }

  const data = {
    labels: ["Cartão", "Dinheiro", "Online"],
    datasets: [
      {
        data: [pedidosCountCartao, pedidosCountDinheiro, pedidosCountOnline],
        backgroundColor: ["#212121", "#1BC5BD", "#F64E60"],
        hoverBackgroundColor: ["#212121", "#1BC5BD", "#F64E60"],
      },
    ],
  };

  const dataValor = {
    labels: ["Cartão", "Dinheiro", "Online"],
    datasets: [
      {
        data: [pedidosSomaCartao, pedidosSomaDinheiro, pedidosSomaOnline],
        backgroundColor: ["#212121", "#1BC5BD", "#F64E60"],
        hoverBackgroundColor: ["#212121", "#1BC5BD", "#F64E60"],
      },
    ],
  };

  const options = {
    legend: {
      display: true,
      position: "left",
    },
  };

  return (
    <>
      <ReactTooltip />
      <div>
        <div
          className="subheader espaco-header-pedidos subheader-transparent"
          id="kt_subheader"
        >
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            {/*begin::Details*/}
            <div className="d-flex align-items-center flex-wrap mr-2">
              {/*begin::Title*/}
              <h5 className="text-dark font-weight-bold mt-2 mr-5">
                Financeiro
              </h5>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 bg-gray-200" />
              {/*end::Separator*/}
              {/*begin::Title*/}
              <h6 className="text-dark mt-2 mr-5 ml-5">Minhas Vendas</h6>
              {/*end::Title*/}
              {/*begin::Separator*/}
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 bg-gray-200" />
              {/*end::Separator*/}
              {/*begin::Title*/}
              <span className="breadcrumb breadcrumb-transparent mt-5 mr-5">
                Histórico
              </span>
              {/*end::Title*/}
            </div>
            {/*end::Details*/}
            {/*begin::Toolbar*/}
            <div className="d-flex align-items-center"></div>
            {/*end::Toolbar*/}
          </div>
        </div>
        {/*begin::Entry*/}
        <div className="d-flex flex-column-fluid">
          {/*begin::Container*/}
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-custom mr-5 pt-5 pl-5 pr-5 pb-5">
                  <div className="row border-bottom">
                    <div className="col-md-1">
                      <label>
                        <i className="fas fa-filter mr-3"></i>
                        <b>Filtros</b>
                      </label>
                    </div>
                    <div className="col-md-2 form-group">
                      <select
                        initialValue={periodo}
                        onChange={(e) => {
                          setPeriodo(e.target.value);
                        }}
                        className="form-control"
                      >
                        <option value="0" selected={periodo === "0"}>
                          Dia atual
                        </option>
                        <option value="7" selected={periodo === "7"}>
                          Últimos 7 dias
                        </option>
                        <option value="30" selected={periodo === "30"}>
                          Últimos 30 dias
                        </option>
                        <option value="60" selected={periodo === "60"}>
                          Últimos 60 dias
                        </option>
                        <option value="90" selected={periodo === "90"}>
                          Últimos 90 dias
                        </option>
                        <option value="PP" selected={periodo === "PP"}>
                          Período Personalizado
                        </option>
                      </select>
                    </div>
                    {periodo === "PP" && (
                      <>
                        <div className="col-md-2 form-group">
                          <div className="input-group">
                            <DatePicker
                              onChange={setDataInicial}
                              isClearable
                              selectsStart
                              locale="pt-BR"
                              className="form-control"
                              placeholderText="dd/mm/aaaa"
                              selected={dataInicial}
                              dateFormat="dd/MM/y"
                              startDate={dataInicial}
                              endDate={dataFinal}
                              customInput={<InputMask mask="99/99/9999" />}
                            />
                          </div>
                        </div>
                        <div className="col-md-2 form-group">
                          <div className="input-group">
                            <DatePicker
                              onChange={setDataFinal}
                              isClearable
                              locale="pt-BR"
                              className="form-control"
                              placeholderText="dd/mm/aaaa"
                              selected={dataFinal}
                              dateFormat="dd/MM/y"
                              selectsEnd
                              startDate={dataInicial}
                              endDate={dataFinal}
                              minDate={dataInicial}
                              customInput={<InputMask mask="99/99/9999" />}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div className="col-md-2">
                      {/*begin::Button*/}
                      <button
                        onClick={(e) => {
                          filtrar();
                        }}
                        className="btn btn-primary btn-block"
                      >
                        <i className="fas fa-filter" /> Filtrar
                      </button>
                      {/*end::Button*/}
                      {/*begin::Button*/}
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-2">
                      <label>Valor total</label>{" "}
                      <i
                        className="fas fa-question-circle"
                        data-tip={
                          "Valor total = Valor em Produtos + Taxa Entrega"
                        }
                      />
                      <br />
                      <h2 className="text-primary">
                        <small>R$ </small>
                        {pedidosSomaTotal}
                      </h2>
                      <br />
                      <label>Quantidade</label>
                      <br />
                      <h2 className="text-primary">{pedidosCountTotal}</h2>
                    </div>

                    <div className="col-md-6 border-left">
                      <div className="row">
                        <div className="col text-center">
                          <label>
                            <b>Tipo de venda</b>
                          </label>
                        </div>
                        <div className="col text-center">
                          <label>
                            <b>Nº de vendas</b>
                          </label>
                        </div>
                        <div className="col text-center">
                          <label>
                            <b>Total </b>
                            <i
                              className="fas fa-question-circle"
                              data-tip={
                                "Total = Valor em Produtos + Taxa Entrega"
                              }
                            />
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-center">
                          <label>Cartão</label>
                        </div>
                        <div className="col text-center">
                          <label>{pedidosCountCartao}</label>
                        </div>
                        <div className="col text-right">
                          <label>
                            {pedidosSomaCartao.toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-center">
                          <label>Dinheiro</label>
                        </div>
                        <div className="col text-center">
                          <label>{pedidosCountDinheiro}</label>
                        </div>
                        <div className="col text-right">
                          <label>
                            {pedidosSomaDinheiro.toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-center">
                          <label>Online</label>
                        </div>
                        <div className="col text-center">
                          <label>{pedidosCountOnline}</label>
                        </div>
                        <div className="col text-right">
                          <label>
                            {pedidosSomaOnline.toLocaleString("pt-br", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 border-left">
                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            id="qtde-tab"
                            data-toggle="tab"
                            href="#qtde"
                          >
                            <span class="nav-text">Nº de Vendas</span>
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="valor-tab"
                            data-toggle="tab"
                            href="#valor"
                          >
                            <span class="nav-text">Valor</span>
                          </a>
                        </li>
                      </ul>

                      <div class="tab-content mt-5" id="myTabContent">
                        <div
                          class="tab-pane fade show active"
                          id="qtde"
                          role="tabpanel"
                          aria-labelledby="qtde-tab"
                        >
                          <Doughnut data={data} options={options} />
                        </div>
                        <div
                          class="tab-pane fade"
                          id="valor"
                          role="valor"
                          aria-labelledby="valor-tab"
                        >
                          <Doughnut data={dataValor} options={options} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br />

            <div className="card card-custom">
              <div className="ml-10 mr-10 mt-10">
                <LoadingOverlay
                  active={caProcessa}
                  spinner
                  text="Carregando..."
                >
                  <S.Table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Pedido</th>
                        <th>Data</th>
                        <th>Cliente</th>
                        <th>Status</th>
                        <th className="text-center">Subtotal</th>
                        <th className="text-center">Desconto</th>
                        <th className="text-center">Taxa Entrega</th>
                        <th className="text-center">Tipo de pagamento</th>
                        <th className="text-center"></th>
                      </tr>
                    </thead>

                    <tbody>
                      {pedidos !== null &&
                        pedidos.map((pedido) => {
                          var pagamento = "";

                          if (
                            pedido?.pagamento == -2 ||
                            pedido?.pagamento == -10 ||
                            pedido?.pagamento == -11 ||
                            pedido?.pagamento == -12
                          ) {
                            pagamento = "Online";
                          } else if (pedido?.pagamento == -1) {
                            pagamento = "Dinheiro";
                          } else {
                            pagamento = "Cartão";
                          }

                          return (
                            <>
                              <ReactTooltip />
                              <tr>
                                <td>#{pedido.hash_pedido}</td>
                                <td>{pedido.data_criacao}</td>
                                <td>{pedido.usuario_nome}</td>
                                <td>{renderStatus(pedido.status)}</td>
                                <td className="text-center">
                                  {new Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  }).format(pedido.valor_pedido)}
                                </td>
                                <td className="text-center">
                                  {new Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  }).format(pedido.valor_desconto)}
                                </td>
                                <td className="text-center">
                                  {new Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  }).format(pedido.valor_taxa)}
                                </td>
                                <td className="text-center">{pagamento}</td>
                                <td className="text-center">
                                  {pedido.status === "PC" &&
                                    !pedido?.fine_reversal_id && (
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          id="dropdown-basic"
                                          className="btn btn-light-primary btn-icon btn-sm text-center dropdown-nocaret"
                                          style={{ borderRadius: 4 }}
                                        >
                                          <i className="ki ki-bold-more-ver" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={() =>
                                              handleOpenModal(pedido)
                                            }
                                          >
                                            Solicitar estorno de multa
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    )}
                                  {pedido.status === "PC" &&
                                    !!pedido?.fine_reversal_id &&
                                    pedido?.fine_reversal_is_approved ===
                                      null && (
                                      <PiWarningFill
                                        size={20}
                                        color="#fcc500"
                                        data-tip="Solicitação de analise de estorno de multa em andamento"
                                      />
                                    )}

                                  {!!pedido?.fine_reversal_id &&
                                    pedido?.fine_reversal_is_approved ===
                                      "1" && (
                                      <RiExchangeDollarLine
                                        size={20}
                                        color="#70CA78"
                                        data-tip="Solicitação de estorno de multa aprovada"
                                      />
                                    )}

                                  {!!pedido?.fine_reversal_id &&
                                    pedido?.fine_reversal_is_approved ===
                                      "0" && (
                                      <MdBlock
                                        size={20}
                                        color="#ff3300"
                                        data-tip="Solicitação de estorno de multa reprovada"
                                      />
                                    )}
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </S.Table>
                  <Modal
                    data={selectedOrder}
                    show={showModal}
                    onClose={handleCloseModal}
                  />
                </LoadingOverlay>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(FVHistorico);
