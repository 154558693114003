import * as Styled from "./styles";

export const Header = () => {
  return (
    <thead>
      <tr>
        <th>
          <Styled.TableHeaderTitle>ID</Styled.TableHeaderTitle>
        </th>

        <th>
          <Styled.TableHeaderTitle>Período</Styled.TableHeaderTitle>
        </th>

        <th>
          <Styled.TableHeaderTitle>Valor</Styled.TableHeaderTitle>
        </th>

        <th>
          <Styled.TableHeaderTitle>Vencimento</Styled.TableHeaderTitle>
        </th>

        <th>
          <Styled.TableHeaderTitle>Status</Styled.TableHeaderTitle>
        </th>

        <th>
          <Styled.TableHeaderTitle>Ações</Styled.TableHeaderTitle>
        </th>
      </tr>
    </thead>
  );
};
