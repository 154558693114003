import styled from "styled-components";
import { invoicesStatus, legacyInvoicesStatus } from "../../helpers";

export const Table = styled.table.attrs({ className: "table" })``;

export const TableHeaderTitle = styled.span`
  font-family: "Nunito";
  font-weight: 600;
  font-size: 16px;

  color: #333333;
`;

export const Cell = styled.td`
  vertical-align: middle !important;
`;

export const TableRowLabel = styled.p`
  font-family: "Nunito";
  font-weight: 400;
  font-size: 14px;

  color: #333333;

  margin: 0px !important;
`;

export const StatusRowLabel = styled(TableRowLabel)`
  background-color: ${({ status }) => invoicesStatus[status].color};
  color: #ffffff;

  border-radius: 4px;

  width: 84px;
  height: 26px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StatusLegacyRowLabel = styled(TableRowLabel)`
  background-color: ${({ status }) => legacyInvoicesStatus[status].color};
  color: #ffffff;

  border-radius: 4px;

  width: 84px;
  height: 26px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ActionsButtonsContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;

  i + i {
    margin-top: -6px;
  }
`;

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;

  cursor: pointer;
`;

export const TooltipContent = styled.a`
  padding: 10px;

  & + & {
    border-top-style: solid;
    border-top-width: 1px;
    border-color: #dcdcdc;
  }

  &:hover {
    background-color: #f4f4f4;
  }
`;

export const TooltipText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #333333;
`;
