import styled from 'styled-components';

export const TextAreaWrapper = styled.div`
    background-color: #F1F3F4;
    border-radius: 8px;
    font-family: "Nunito";
    font-size: 14px;
    padding: 10px;
`;

export const TextareaField = styled.textarea`
    width: 100%;
    height: 120px;
    padding: 8px;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-family: "Nunito";
    background-color: transparent;
    resize: none;
    outline-width: 0;
`;

export const TextCounter = styled.div`
  width: 100%;
  text-align: right;
`;
