import styled from "styled-components";

export const Container = styled.a`
  min-width: 30px;
  min-height: 30px;

  max-width: fit-content;

  border: none;
  border-radius: 4px;
  background-color: #f1f3f4;
  padding: 6px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;
