import React from "react";

import * as S from "./styles";

export function Aside({ toggle, isOpen, position = "right", children }) {
  // console.log({ toggle, isOpen, position, children });

  return (
    <>
      <S.Overlay onClick={toggle} $show={isOpen} />
      <S.DrawerContainer $show={isOpen} $position={position}>
        <S.Content>{children}</S.Content>
      </S.DrawerContainer>
    </>
  );
}
