import React from "react";

import { Pagination } from "../../components/Pagination";
import { Table } from "./components/Table/Table";
import { Feedback } from "./components/Feedback";
import { LoadingFeedback } from "./components/LoadingFeedback";
import { Root } from "./components/Root";

import { useInvoices } from "./useInvoices";

export const Invoices = () => {
  const {
    invoices,
    legacyInvoices,
    isLoading,
    isError,
    currentPage,
    setCurrentPage,
    totalPages,
    isEmptyInvoices,
  } = useInvoices();

  if (isEmptyInvoices) {
    return (
      <Root>
        <Feedback
          imagePath="assets/imgs/void.png"
          title="Nada por aqui"
          description="Nenhuma fatura para listar."
        />
      </Root>
    );
  }

  return (
    <Root>
      {isLoading && <LoadingFeedback />}

      {isError && (
        <Feedback
          imagePath="assets/imgs/error.png"
          title="Não foi possível listar as faturas"
          description="Tente recarregar a página e se o problema persistir, contate o suporte."
        />
      )}

      {!isLoading && !isError && (
        <>
          <Table
            invoices={invoices}
            legacyInvoices={legacyInvoices}
            currentPage={currentPage}
            lastPage={totalPages}
          />

          {invoices.length > 0 && (
            <Pagination
              totalPages={totalPages}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
            />
          )}
        </>
      )}
    </Root>
  );
};
