import ReactTooltip from "react-tooltip";

import { ActionButton } from "../ActionButton";

import { formatValueToCurrency } from "../../../../utils";
import { legacyInvoicesStatus } from "../../helpers";

import * as Styled from "./styles";

export const LegacyRow = ({ row, ...rest }) => {
  return (
    <tr {...rest}>
      <Styled.Cell>
        <Styled.TableRowLabel>{row.hash_fatura}</Styled.TableRowLabel>
      </Styled.Cell>

      <Styled.Cell>
        <Styled.TableRowLabel>{row.referencia}</Styled.TableRowLabel>
      </Styled.Cell>

      <Styled.Cell>
        <Styled.TableRowLabel>
          {formatValueToCurrency(Number(row.valor_pagar))}
        </Styled.TableRowLabel>
      </Styled.Cell>

      <Styled.Cell>
        <Styled.TableRowLabel>{row.data_vencimento}</Styled.TableRowLabel>
      </Styled.Cell>

      <Styled.Cell>
        <Styled.StatusLegacyRowLabel status={row.status}>
          {legacyInvoicesStatus[row.status].label}
        </Styled.StatusLegacyRowLabel>
      </Styled.Cell>

      <Styled.Cell>
        <Styled.ActionsButtonsContainer>
          <ReactTooltip />

          <ActionButton
            href={row.fatura_link}
            icon={<i className="far fa-file" style={{ color: "#1B78B3" }} />}
            data-tip="Fatura"
          />
          {row.status === "PG" && (
            <ActionButton
              href={row.nfse_link}
              icon={
                <i
                  className="las la-receipt"
                  style={{ color: "#FF3333", fontSize: 18 }}
                />
              }
              data-tip="Nota fiscal"
            />
          )}
          {(row.status !== "IS" || row.status !== "PG") && row.boleto_link && (
            <ActionButton
              href={row.boleto_link}
              icon={
                <i
                  className="far fa-money-bill-alt"
                  style={{ color: "#00B333" }}
                />
              }
              data-tip="Pagar"
            />
          )}
        </Styled.ActionsButtonsContainer>
      </Styled.Cell>
    </tr>
  );
};
