import React, { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { format } from "date-fns";

import { Aside } from "../../../../../components/Aside";
import { formatValueToCurrency } from "../../../../../utils/formatValueToCurrency";

import * as S from "./styles";
import { OrdersTable } from "./OrdersTable";
import { useOrderTransactionStore } from "../../../../../store/orderTransactionStore";

function SummaryModal({ settlementPeriod, isOpen, toggle }) {
  const {
    orderTransactions,
    setPerPage,
    setSettlementPeriodIdFilter,
    setOnlinePaymentFilter,
    resetFilter: resetOrderTransactionFilter,
    fetchOrderTransactions,
    grouppedOrderTransactions,
  } = useOrderTransactionStore();

  useEffect(() => {
    resetOrderTransactionFilter();
    if (settlementPeriod) {
      setPerPage(6000);
      setOnlinePaymentFilter(true);
      setSettlementPeriodIdFilter(settlementPeriod.id);
      fetchOrderTransactions();
    }
  }, [fetchOrderTransactions, resetOrderTransactionFilter, setOnlinePaymentFilter, setPerPage, setSettlementPeriodIdFilter, settlementPeriod]);

  if (!settlementPeriod) {
    return null;
  }

  return (
    <>
      <ReactTooltip className="tooltip" effect="solid" />
      <Aside toggle={toggle} isOpen={isOpen}>
        <S.Container>
          <S.Header>
            <div>
              <S.Text $weight="600">Total a receber</S.Text>
              <S.Text size={20} $weight="600" color="#00B333">
                {formatValueToCurrency(
                  settlementPeriod.merchant_split_value / 100
                )}
              </S.Text>
              <S.Text size={12}>Em {format(new Date(`${settlementPeriod.settlement_date} 12:00:00`), "dd/MM/yyyy")}</S.Text>
            </div>
            <div>
              <S.Text $weight="600">Total vendido via pag. online</S.Text>
              <S.Text size={20} $weight="600">
                {formatValueToCurrency(settlementPeriod.total_value / 100)}
              </S.Text>
              <S.Text size={12}>
                Subototal {formatValueToCurrency(settlementPeriod.subtotal_value / 100)}
              </S.Text>
              <S.Text size={12}>
                Taxa de entrega {formatValueToCurrency(settlementPeriod.delivery_value / 100)}
              </S.Text>
            </div>
            <div>
              <S.Text $weight="600">Taxa de pag. online</S.Text>
              <S.Text size={20} $weight="600">
                {formatValueToCurrency(
                  settlementPeriod.online_payment_value / 100
                )}
              </S.Text>
            </div>
            <div>
              <S.Text $weight="600">Comissão</S.Text>
              <S.Text size={20} $weight="600">
                {formatValueToCurrency(settlementPeriod.commission_value / 100)}
              </S.Text>
            </div>
            <div>
              <S.Text $weight="600">
                Retido
                <img
                  src="/icon-help.svg"
                  alt="ajuda"
                  style={{ marginLeft: 4, height: 14 }}
                  data-tip="Retido é o valor da comissão do pede.ai, abatido diretamente nas vendas via pagamento online. Essa parte da comissão não será mais cobrada na sua fatura."
                />
              </S.Text>
              <S.Text size={20} $weight="600">
                {formatValueToCurrency(settlementPeriod.retained_value / 100)}
              </S.Text>
            </div>
          </S.Header>
          {/* // Vai ser implementado em breve */}
          {/* <div>
            <S.Title>Transferências</S.Title>
            <TransfersTable />
          </div> */}
          <div style={{flexGrow: 1, overflow: 'scroll', marginBottom: 36}}>
            <S.Title>Pedidos</S.Title>
            <OrdersTable
              grouppedOrderTransactions={grouppedOrderTransactions}
              orderTransactions={orderTransactions}
            />
            <div></div>
          </div>
          <S.Discount>
            <div>
              <S.Title>Retido</S.Title>
              <S.Text>
                Retido é o valor da comissão do Aiboo, abatido diretamente nas
                vendas via pagamento online. Essa parte da comissão não será
                mais cobrada na sua fatura.
              </S.Text>
            </div>
            <div>
              <S.Text size={20} $weight="600">
                {formatValueToCurrency(settlementPeriod.retained_value / 100)}
              </S.Text>
            </div>
          </S.Discount>
          <S.Footer>
            <S.Text size={20} $weight="600">
              Total a receber
            </S.Text>
            <S.Text size={20} $weight="600" color="#00B333">
              {formatValueToCurrency(settlementPeriod.merchant_split_value / 100)}
            </S.Text>
          </S.Footer>
        </S.Container>
      </Aside>
    </>
  );
}

export default SummaryModal;
