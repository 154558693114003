export const legacyInvoicesStatus = {
  PG: {
    label: "Pago",
    color: "#00B333",
  },
  AT: {
    label: "Pendente",
    color: "#FFC700",
  },
  VC: {
    label: "Vencido",
    color: "#FF3333",
  },
  IS: {
    label: "Isenta",
    color: "#BF6BF2",
  },
  FA: {
    label: "Acumulado",
    color: "#999999",
  },
};

export const invoicesStatus = {
  processing: {
    label: "Processando",
    color: "#BF6BF2",
  },
  accumulated: {
    label: "Acumulado",
    color: "#999999",
  },
  pending: {
    label: "Pendente",
    color: "#FFC700",
  },
  overdue: {
    label: "Vencido",
    color: "#FF3333",
  },
  paid: {
    label: "Pago",
    color: "#00B333",
  },
};
