import React from "react";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";

import { FiChevronRight } from "react-icons/fi";

import Subtotal from "../../icons/mdi_basket-outline.svg";
import Delivery from "../../icons/mdi_motorbike.svg";
import Money from "../../icons/tdesign_money.svg";
import CreditCard from "../../icons/material-symbols_credit-card-outline.svg";
import Coupon from "../../icons/mdi_voucher-outline.svg";
import Download from "../../icons/material-symbols_download.svg";
import Help from "../../icons/material-symbols_help-outline.svg";

import * as S from "./styles";

function HeaderSummary({
  financialPeriod,
  financialPeriodSummary,
  financialPeriodInvoice,
}) {
  if (!financialPeriod) {
    return null;
  }

  return (
    <S.Container>
      <ReactTooltip className="tooltip" effect="solid" />
      <S.HStack $justify="flex-end" $mb={18} $gap={7}>
        {!!financialPeriodInvoice.invoiceUrl && (
          <S.Link href={financialPeriodInvoice.invoiceUrl} target="_blank">
            <S.Icon src={Download} />
            <span>Baixar fatura</span>
          </S.Link>
        )}
        {!!financialPeriodInvoice.bankSlipUrl && (
          <S.Link href={financialPeriodInvoice.bankSlipUrl} target="_blank">
            <S.Icon src={Download} />
            <span>Baixar boleto</span>
          </S.Link>
        )}

        {!!financialPeriodInvoice.nfseUrl && (
          <S.Link href={financialPeriodInvoice.nfseUrl} target="_blank">
            <S.Icon src={Download} />
            <span>Baixar nota fiscal</span>
          </S.Link>
        )}
      </S.HStack>

      <S.Summary>
        <div>
          <S.HStack $gap={9}>
            <S.Text $size={16} $weight="bold" $mb={17}>
              Total vendido
            </S.Text>
            <S.Text $size={14} $mb={17}>
              ({financialPeriodSummary.orderCount} pedido
              {financialPeriodSummary.orderCount > 1 ? "s" : ""})
            </S.Text>
          </S.HStack>

          <S.Text $size={24} $weight="bold" color="#00B333" $mb={17}>
            {Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(financialPeriodSummary.totalValue / 100)}
          </S.Text>
          <S.Text>
            <S.Icon src={Subtotal} />
            Subtotal:{" "}
            {Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(financialPeriodSummary.subtotalValue / 100)}
            {/* {" (Comissionável: "}
            {Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(financialPeriodSummary.commissionableSubtotalValue / 100)}
            {")"} */}
          </S.Text>
          <S.Text $mb={25}>
            <S.Icon src={Delivery} />
            Taxa de entrega:{" "}
            {Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(financialPeriodSummary.deliveryValue / 100)}
            {/* {" (Comissionável: "}
            {Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(financialPeriodSummary.commissionableDeliveryValue / 100)}
            {")"} */}
          </S.Text>
          <S.Text>
            Recebido via estabelecimento:{" "}
            {Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(financialPeriodSummary.totalOfflinePaymentValue / 100)}
          </S.Text>
          <S.Text>
            <S.Icon src={Money} />
            Dinheiro:{" "}
            {Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(financialPeriodSummary.cashValue / 100)}
          </S.Text>
          <S.Text>
            <S.Icon src={CreditCard} />
            Cartão na entrega:{" "}
            {Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(financialPeriodSummary.cardOnDeliveryValue / 100)}
          </S.Text>
          <S.Text $mb={25}>
            <S.Icon src={Coupon} />
            Pago com cupom:{" "}
            {Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(financialPeriodSummary.totalDiscountValue / 100)}
          </S.Text>
          <S.HStack $gap={24}>
            <S.Text>
              Recebido via Aiboo:{" "}
              {Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(financialPeriodSummary.totalOnlinePaymentValue / 100)}
            </S.Text>
            <Link to="/financeiro/repasses">
              <S.Link>
                <span>Ver repasses</span>
                <FiChevronRight color="#333" style={{ marginLeft: 8 }} />
              </S.Link>
            </Link>
          </S.HStack>
        </div>

        <div>
          <S.HStack $justify="space-between">
            <S.Text $size={16} $weight="bold" $mb={17}>
              Comissão
            </S.Text>
            <S.Text $size={14} $mb={17}>
              Período de {financialPeriod.completeLabel}
            </S.Text>
          </S.HStack>
          <S.Text $size={24} $weight="bold" $mb={17}>
            {Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(financialPeriodSummary.commissionValue / 100)}
          </S.Text>
          <S.Text>
            Taxa de pagamento online:{" "}
            {Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(financialPeriodSummary.onlinePaymentValue / 100)}
          </S.Text>
          <S.Text>
            Multa pedido recusado:{" "}
            {Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(financialPeriodSummary.fineValue / 100)}
          </S.Text>
          <S.Text>
            Cupons custeados pelo Aiboo: -{" "}
            {Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(financialPeriodSummary.sponsoredDiscountValue / 100)}
            <S.Icon src={Help} data-tip="Cupons custeados pelo Aiboo" />
          </S.Text>
          <S.Text $mb={17}>
            Transações adicionais:{" "}
            {Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(financialPeriodSummary.customTransactionValue / 100)}
            <S.Icon
              data-tip="São transações realizadas para ajustar valores devidos ou recebidos pelo lojista"
              src={Help}
            />
          </S.Text>
          <S.TextWithBorder $mb={27}>
            Valor retido: -{" "}
            {Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(financialPeriodSummary.retainedValue / 100)}
            <S.Icon
              src={Help}
              data-tip="É o valor da comissão abatido diretamente nas vendas via pagamento online. Esse parte da comissão não será mais cobrada na sua fatura."
            />
          </S.TextWithBorder>
          <S.Text>
            Valor da fatura:{" "}
            {Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(
              (financialPeriodSummary.balanceType === "accumulated"
                ? 0
                : financialPeriodSummary.balanceValue) / 100
            )}
          </S.Text>
          {financialPeriodSummary.balanceType === "accumulated" && (
            <S.Text>
              {financialPeriodSummary.balanceValue < 0
                ? "Desconto na próxima fatura: "
                : "Acrescimo na próxima fatura: "}
              {Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(Math.abs(financialPeriodSummary.balanceValue) / 100)}
            </S.Text>
          )}
        </div>
      </S.Summary>
    </S.Container>
  );
}

export default HeaderSummary;
