import React from "react";

import * as S from "./styles";

export function TextArea({ onChange, value }) {
  const handleChange = (event) => {
    onChange(event.target.value.slice(0, 200));
  };

  return (
    <S.TextAreaWrapper>
      <S.TextareaField value={value} onChange={handleChange} />
      <S.TextCounter>{value.length}/200</S.TextCounter>
    </S.TextAreaWrapper>
  );
}
